/*! Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;1,300;1,400&display=swap");

/*! Tailwind Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  height: 100%;
  background-color: #141414;
}

/*! Hero */
/* Heading underline */
.heroUnderline {
  background-image: linear-gradient(90deg, #5f4c99, #5f4c99);
  background-position: 15% 85%;
  background-size: 100% 15%;
  background-repeat: no-repeat;
  width: 100%;
}

/* Bio underlines */
.bioUnderline {
  background-image: linear-gradient(90deg, #333333, #333333);
  background-position: 15% 85%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
}

.bioDataUnderline {
  background-image: linear-gradient(90deg, #a04048, #a04048);
  background-position: 15% 85%;
  background-size: 100% 85%;
  background-repeat: no-repeat;
  width: 100%;
}

/* Typewriter effect */
.typewriter {
  white-space: nowrap;
  overflow: hidden;
  animation: animated-text 2s steps(30, end) 1 normal both;
}
@keyframes animated-text {
  from {
    width: 0;
  }
  to {
    width: 37%;
  }
}

/*! Background */
/* Heading underline */
.backgroundUnderline {
  background-image: linear-gradient(90deg, #5f4c99, #5f4c99);
  background-position: 15% 95%;
  background-size: 100% 25%;
  background-repeat: no-repeat;
}

/* Card underlines */
.frontendUnderline {
  background-image: linear-gradient(90deg, #a04048, #a04048);
  background-position: 15% 92%;
  background-size: 100% 20%;
  background-repeat: no-repeat;
}

.backendUnderline {
  background-image: linear-gradient(90deg, rgba(168, 115, 36, 0.9), rgba(168, 115, 36, 0.9));
  background-position: 15% 92%;
  background-size: 100% 20%;
  background-repeat: no-repeat;
}

.toolsUnderline {
  /* readable orange accent */
  background-image: linear-gradient(90deg, #b35919, #b35919);
  /* readable gold accent */
  /* background-image: linear-gradient(90deg, #9E6942, #9E6942); */
  background-position: 15% 92%;
  background-size: 100% 20%;
  background-repeat: no-repeat;
}

.expertiseBgImage {
  background-image: url("./assets/images/code-screenshot.png");
  background-position: 103% 87%;
  background-size: 100% ;
  background-repeat: no-repeat;
}

/*! Projects */
/* Heading underline */
.projectHeadingUnderline {
  background-image: linear-gradient(90deg, #5f4c99, #5f4c99);
  background-position: 15% 82%;
  background-size: 100% 18%;
  background-repeat: no-repeat;
}

/* Featured Heading Underline */
.featuredProjectsUnderline {
  background-image: linear-gradient(90deg, #a04048, #a04048);
  background-position: 15% 93%;
  background-size: 100% 22%;
  background-repeat: no-repeat;
}

/* In Progress Heading Underline */
.inProgressProjectUnderline {
  background-image: linear-gradient(90deg, #b35919, #b35919);
  background-position: 15% 93%;
  background-size: 100% 22%;
  background-repeat: no-repeat;
}

/* Other Projects Heading Underline */
.otherProjectsUnderline {
  /* readable gold accent */
  background-image: linear-gradient(90deg, rgba(168, 115, 36, 0.9), rgba(168, 115, 36, 0.9));
  background-position: 15% 94%;
  background-size: 100% 22%;
  background-repeat: no-repeat;
}

/* Project Card Title Underline */
.projectCardTitle {
  background-image: linear-gradient(#2E294E, #A04048);
  background-position: 15% 87%;
  background-size: 100% 35%;
  background-repeat: no-repeat;
}

/* ! Contact */
/* Heading underline */
.contactHeadingUnderline {
  background-image: linear-gradient(90deg, #5f4c99, #5f4c99);
  background-position: 15% 85%;
  background-size: 100% 25%;
  background-repeat: no-repeat;
}

/* Toastify Notification */
#notifyToast {
  /* border-radius: 6px !important; */
  background: #0B7A75 !important;
  color: #EBEBEB;
  /* opacity: 0.7; */
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 6px 16px;
  border-radius: 3px;
}
